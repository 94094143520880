<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper p-0 m-1 text-center">
        <div class="row">
          <div class="col-md-12">
            <div
              role="alert"
              class="alert bg-frontera text-center text-white m-0 p-0"
            >
              <h3
                v-for="venEnturnamiento in ventanaEnturnamiento.sitios"
                :key="venEnturnamiento.id"
              >
                <strong>{{ venEnturnamiento.nombre }}</strong>
              </h3>
            </div>
          </div>
          <div class="col-md-8">
            <div class="col-md-12">
              <div role="alert" class="alert bg-light text-center m-0 p-1">
                <h4>
                  <strong>{{ ventanaEnturnamiento.nombre }}</strong>
                </h4>
              </div>
            </div>
            <div class="row">
              <div
                :class="
                  'col-md-' +
                    (Math.round(12 / data.length) < 2
                      ? 2
                      : Math.round(12 / data.length))
                "
                v-for="dato in data"
                :key="dato.id"
              >
                <div class="card m-2">
                  <div class="card-header bg-frontera p-1">
                    <h2 class="card-title text-white m-0">
                      <strong v-if="buscarAgrup(1) !== undefined"
                        >Sitio Cargue: {{ dato.sitio_cargue.nombre }}</strong
                      >
                      <strong v-if="buscarAgrup(2) !== undefined">
                        Transportadora:
                        {{ dato.transportadora.razon_social }}
                      </strong>
                      <strong v-if="buscarAgrup(3) !== undefined">
                        Tipo Producto:
                        {{ dato.tipo_producto.nombre }}
                      </strong>
                      <strong v-if="buscarAgrup(4) !== undefined"
                        >Sitio Descargue:
                        {{ dato.sitio_descargue.nombre }}</strong
                      >
                      <strong v-if="buscarAgrup(5) !== undefined"
                        >Tipo Ruta: {{ dato.tipo_ruta }}</strong
                      >
                      <strong v-if="buscarAgrup(6) !== undefined"
                        >Producto: {{ dato.producto_liquido.nombre }}</strong
                      >
                    </h2>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0 table-responsive">
                    <ul
                      class="list-group"
                      v-for="(detalles, index) in dato.datos"
                      :key="index"
                    >
                      <li
                        class="list-group-item d-flex justify-content-between align-items-center p-2"
                      >
                        <strong> {{ detalles.vehiculo.placa }} </strong>
                        <span class="badge badge-primary badge-pill"
                        style="font-size: 13px;">
                          {{ index + 1 }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div role="alert" class="alert bg-light text-center m-0 mt-4">
            </div>
            <div>
              <iframe
                width="490"
                height="400"
                v-bind:src="url"
                frameborder="0"
                allow='autoplay'
              ></iframe>
              <!-- <video 
              class="play"
              autoplay="true"
              src="https://www.youtube.com/watch?v=RYnFIRc0k6E"
              width="400"
            ></video> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      detalles: {},
      turnos: [],
      timer: null,
      contador: 0,
      cont: 0,
      data: [],
      ventanasEnturne: [],
      ventana_enturnamiento_id: null,
      ventanaEnturnamiento: {},
      url: "",
    };
  },

  methods: {
    hide() {
      document.querySelector(".main-sidebar").style.display = "none";
      document.querySelector(".main-header").style.display = "none";
      //document.querySelector('.main-footer').style.display = 'none';
    },

    async buscarVentanasEnturne() {
      this.ventanasEnturne = [];
      let me = this;
      await axios
        .get("api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas", {
          params: {
            sitio_id: this.$route.query.sitio_id,
            sitio_type: this.$route.query.sitio_type,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.ventanasEnturne = respuesta;
          me.ventana_enturnamiento_id = me.ventanasEnturne[0].id;
          me.ventanaEnturnamiento = me.ventanasEnturne[0];
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async init() {
      let me = this;
      me.encabezado = {};
      await axios
        .get(
          "api/hidrocarburos/ventanasEnturnamientoOperacion/mostrarVentana",
          {
            params: {
              ventana_enturnamiento_id: me.ventana_enturnamiento_id,
            },
          }
        )
        .then(function(response) {
          me.data = response.data;
          //me.start(response.data)
        });
    },

    buscarAgrup(criterio) {
      if (this.ventanaEnturnamiento !== null) {
        let res = this.ventanaEnturnamiento.agrupamiento.find(
          (n) => n.criterio === criterio
        );
        return res;
      }
      return undefined;
    },

    start() {
      this.timer = setInterval(() => {
        this.cont++;
        if (this.cont == 50) {
          this.contador++;
          if (this.contador == this.ventanasEnturne.length) {
            this.contador = 0;
          }
          this.ventana_enturnamiento_id = this.ventanasEnturne[
            this.contador
          ].id;
          this.ventanaEnturnamiento = this.ventanasEnturne[this.contador];
          this.init();
          this.cont = 0;
        }
      }, 1000);
    },
  },

  beforeMount() {
    this.hide();
  },

  async mounted() {
    await this.buscarVentanasEnturne();
    await this.init();
    this.start();
  },
};
</script>

<style>
</style>
